.profile-container {
  height: 100%;
  padding-top: 3%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
}

h2 {
  margin-bottom: 0;
}

.row {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  padding-bottom: 8%;
  width: 100%;
  font-size: 16px;
}

.row a {
  text-decoration:none;
}


@media (max-width: 1185px) {
.profile-container {
  padding: 0;
  margin: 0;
  border-radius: 0; 
}
.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.profile-lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.row {
  width: 35%;
  padding-bottom: 3%;
}
.profile-container h4, 
.profile-container span, 
.profile-container div {
  color: rgb(30, 56, 133)
}
}
@media (max-width: 768px) {
  .row {
    width: 50%;
  }
}
@media (max-width: 498px) {
  .row {
    width: 70%;
  }
}