.citation{
    text-align: center;
    padding-bottom: 3%;
    padding-inline: 20%;
    padding-top: 3%;
}

.presentation-sentence {
    padding-bottom: 3%;
    padding-inline: 5%;
    text-indent: 2%;
    letter-spacing: 0.8px;
    color: black;
    text-align: justify;
}

@media (max-width: 930px) {
    .citation{
        padding-inline: 5%;
        font-size: 1.1em;
    }
    .presentation-sentence {
        padding-bottom: 1%;
        font-size: 0.9em;
    }
}

@media (max-width: 768px) {
    .citation{
        padding-inline: 5%;
        padding-bottom: 2%;
        font-size: 1.3em;
    }
    .presentation-sentence {
        font-size: 1.1em;
        padding-bottom: 3%;
        text-indent: 5%;
        text-align: justify;
    }
    .container-global-about {
        padding-bottom: 15%;
        padding-inline: 2%;
    }
}

