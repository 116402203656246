.modal-project-container {
  width: 100%;
}

.project-button-label {
  padding-right: 5%;
  font-weight: 600;
  letter-spacing: 1px;
}

.modal-title {
  text-align: center;
}

@media (max-width: 1185px) {

  
}

@media (max-width: 600px) {

}