.timeline-container {
    padding-right: 5%;
    padding-bottom: 5%;
}

.timeline-icon {
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

.timeline-date {
    font-size: 0.9em;
}

@media (max-width: 768px) {
    .timeline-container {
        padding-bottom: 15%;
    }
}

