

.backgroundImage {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/img/background_image.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(8px);
    z-index: -1;
  }
  
  .globalContainer {
    height: 100%;
    overflow: hidden;
  }

  .container-global-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  body {
    font-family: "Rethink Sans", sans-serif
  }
  
  .webContainer {
    width: 85%;
    height: 77%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    color: rgb(30, 56, 133) ;
    margin-top: 4%;
    
  }
  
  .mainContentContainer {
    flex: 6;
    height: 100%;
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-right: 2%;
    background-color: rgba(255, 255, 255, 0.85);
  }
  .onlyContentContainer {
    height: 100%;
    padding-top: 2%;
  }

  .profileContainer {
    flex: 2;
    height: 100%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 3%;
    border-radius: 5px;
    justify-content: center;
    align-content : center;
    margin-left: 2%;
    background-color: rgba(255, 255, 255, 0.75);
  }

  /* GlobalStyles */
  /* Largeur et couleur de la barre de défilement */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

/* Couleur de la barre elle-même */
::-webkit-scrollbar-thumb {
  background-color: rgb(30, 56, 133);
}

/* Au survol de la barre */
::-webkit-scrollbar-thumb:hover {
  background-color: '#6e8ade';
}
  /* Bouton survol souris */
  button:hover, input[type='button']:hover, a:hover {
    opacity: 0.7;
  }
  /* Bouton au click */
  button:active, input[type='button']:active, a:active {
    opacity: 0.5;
    transition: 0.2s;
  }
  /* Titre 1 */
   h1 { 
    margin: 0;
    font-size: 1.3em;
    color: rgb(30, 56, 133);
  }
  /* Labels de formulaire */
  label {
    font-size: 1.05em;
    font-weight: 600;
  }
  /* erreur de formulaire */
  .globalFormError {
    font-size: 0.8em;
    color: rgb(217, 24, 24);
    font-weight: 600;
  }
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .align-right {
    display: flex;
    justify-content: flex-end;
  }
  
  .flex-1 {
    flex: 1
  }
  .flex-2 {
    flex: 2
  }
  .flex-3 {
    flex: 3
  }
  .flex-4 {
    flex: 4
  }
  .flex-5 {
    flex: 5
  }
  .flex-6 {
    flex: 6
  }
  .bg-red {
    background-color: red;
  }
  .bg-green {
    background-color: green;
  }
  @media (max-width: 1185px) {
    .webContainer {
      flex-direction: column;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow-y: auto;
    }
    .mainContentContainer {
      padding-inline: 0;
      margin: 0;
      border-radius: 0;
      overflow: scroll;
      position: relative;
    }
    .profileContainer {
      background-color: white;
      padding: 0;
      margin: 0;
      height: auto;
      border-radius: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      overflow-y: hidden;
    }
  }
  @media (max-width: 768px) {
    .webContainer {
      /* Styles spécifiques à la version mobile */
      display: block;
      flex-direction: column;
      overflow-y: hidden;
      width: 100%;
      height: 100%;
      border-radius: 0;
      justify-content: center;
      margin: 0;
      padding: 0;
      flex: 1;
    }
    .profileContainer {
      padding: 0;
      margin: 0;
      border-radius: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      overflow-y: hidden;
    }
    .mainContentContainer {
      margin: 0;
      border-radius: 0;
      overflow: scroll;
      position: relative;
      margin-bottom: 15%;
    }
     /* Titre 1 */
    h1 { 
      margin: 0;
      font-size: 0.9em;
      color: rgb(30, 56, 133);
    }
  }