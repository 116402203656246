.title-container {
  width: 40%;
}

.title {
  margin-bottom: 5%;
  flex-wrap: nowrap;
}

.divider {
  width: 50%;
  border : 2px solid rgb(30, 56, 133);
}

@media (max-width: 768px) {
  .title-container{
    width: 60%;
  }
}